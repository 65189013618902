import clsx from "clsx";
import Link from "next/link";

import { HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { SignUpButtonProps } from "@/features/auth/components/signUp/SignUpButton";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";

export const SignInButton = ({ onNavbar }: SignUpButtonProps) => {
  const { trackEvent } = useAnalytics();

  return (
    <div className="flex items-center">
      <Link
        href="/signin"
        passHref
        className={clsx(
          "bg-primary hover:bg-primary/50 h-max flex-1 whitespace-nowrap rounded px-2 text-center text-base text-white",
          !!onNavbar
            ? "sm:text-primary sm:bg-secondary hover:sm:bg-primary/20 py-1 sm:font-light"
            : "py-2",
        )}
        onClick={() => trackEvent(HeaderEventEnum.CLICK_SIGN_IN)}
      >
        登入
      </Link>
    </div>
  );
};
