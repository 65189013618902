import { MobileSideNavItemType } from "@/features/layout/types/MobileSideNavItemType";
import { UserRoleEnum } from "@/generated/graphql-hooks";

export const mobileSideNavItems: MobileSideNavItemType[] = [
  {
    name: "我的通告",
    role: UserRoleEnum.Model,
    path: "/kol/manageJob",
  },
  {
    name: "管理通告",
    role: UserRoleEnum.Business,
    path: "/business/manageJob",
  },
  {
    name: "我的帳單",
    role: UserRoleEnum.Business,
    path: "/business/paymentLog",
  },
  {
    name: "設定",
    role: UserRoleEnum.Business,
    path: "/business/editProfile",
  },
  {
    name: "設定",
    role: UserRoleEnum.Model,
    path: "/kol/editProfile",
  },
];
