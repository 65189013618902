import { isAfter } from "date-fns";
import { useEffect, useMemo } from "react";
import { Waypoint } from "react-waypoint";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { ErrorRetry } from "@/features/common/components/ErrorRetry";
import { useErrorHandling } from "@/features/common/hooks/useErrorHandling";
import { useInvalidateQueries } from "@/features/common/hooks/useInvalidateQueries";
import { EmptyNotification } from "@/features/notification/components/EmptyNotification";
import { NotificationItem } from "@/features/notification/components/NotificationItem";
import { NotificationItemSkeleton } from "@/features/notification/components/NotificationItemSkeleton";
import {
  useInfiniteNotificationsQuery,
  useUpdateUserMutation,
} from "@/generated/graphql-hooks";
import { getPublicConfig } from "@/helpers/getPublicConfig";

const {
  features: {
    notification: { itemsPerQuery },
  },
} = getPublicConfig();

export const NotificationList = () => {
  const { handleGraphqlError } = useErrorHandling();
  const { user } = useUserContext();

  const { mutateAsync: updateUser } = useUpdateUserMutation();
  const { invalidateQueries } = useInvalidateQueries();

  const {
    data: { pages } = {},
    isFetching,
    isFetchedAfterMount,
    hasNextPage,
    error,
    fetchNextPage,
    refetch,
  } = useInfiniteNotificationsQuery(
    null,
    { take: itemsPerQuery },
    {
      enabled: !!user,
      getNextPageParam: (lastPage, pages) => {
        const fetchedCount =
          pages.flatMap((page) => page.notifications)?.length ?? 0;

        if (
          fetchedCount % itemsPerQuery === 0 &&
          fetchedCount !== 0 &&
          fetchedCount < pages?.[0].notificationsCount
        ) {
          return {
            cursor: {
              id: lastPage.notifications?.[lastPage.notifications.length - 1]
                ?.id,
            },
          };
        }

        return false;
      },
    },
  );

  useEffect(() => {
    return () => {
      (async () => {
        try {
          await updateUser({
            data: {
              lastReadAt: {
                set: new Date(),
              },
            },
          });
          invalidateQueries(["User"]);
        } catch (error) {
          handleGraphqlError(error, {
            tags: {
              action: "NotificationList.updateUser",
            },
          });
        }
      })();
    };
  }, []);

  const notifications = useMemo(
    () => pages?.flatMap((page) => page.notifications) ?? [],
    [pages],
  );

  return (
    <ul
      role="list"
      className="max-h-80 space-y-0 divide-y divide-gray-200 overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
      <li className="py-2 text-center text-lg">通知</li>
      {!isFetchedAfterMount && <NotificationItemSkeleton />}
      {isFetchedAfterMount && !!error && <ErrorRetry refetch={refetch} error={error}/>}
      {isFetchedAfterMount && !notifications?.length && !error && (
        <EmptyNotification />
      )}
      {isFetchedAfterMount &&
        notifications?.map((notification) => (
          <li key={notification.id}>
            <NotificationItem
              notification={notification}
              unread={isAfter(
                new Date(notification.createdAt),
                new Date(user?.lastReadAt ?? 0),
              )}
            />
          </li>
        ))}
      {isFetchedAfterMount && !!isFetching && <NotificationItemSkeleton />}
      <div className="mb-3">
        <Waypoint
          onEnter={({ previousPosition }) => {
            if (hasNextPage && !!previousPosition) fetchNextPage();
          }}
          bottomOffset={-5}
        />
      </div>
    </ul>
  );
};
