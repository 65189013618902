import Link from "next/link";
import { useMemo } from "react";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Avatar } from "@/features/common/components/Avatar";
import {
  DialogViewEnum,
  useDialogContext,
} from "@/features/common/contexts/useDialogContext";
import { useUserData } from "@/features/layout/hooks/useUserData";
import { userRoles } from "@/features/user/consts/userRoles";
import ArrowRightIcon from "@/icons/arrow_right.svg";
import CirclePlusIcon from "@/icons/circle_plus.svg";

type MobileSideMenuHeaderProps = {
  onClick: () => void;
};
export const MobileSideMenuHeader = ({
  onClick,
}: MobileSideMenuHeaderProps) => {
  const { user } = useUserContext();
  const { openDialog } = useDialogContext();
  const userData = useUserData();

  return (
    <Link href={userData.path} passHref legacyBehavior>
      <div
        onClick={onClick}
        className="bg-secondary flex px-4 py-3.5 gap-2.5 cursor-pointer"
      >
        <Avatar
          publicId={userData.avatar}
          className="!h-14 !w-14"
          name={userData.username}
        />
        <div className="flex-1 gap-0.75">
          <div className="flex w-full items-baseline justify-between space-x-2 text-lg">
            <span className="text-gray-900">{userData.username}</span>
            <ArrowRightIcon className="text-gray-400" />
          </div>
          {typeof userData.credit !== "undefined" ? (
            <div className="flex gap-4 items-center">
              <div className="text-sm text-gray-500 font-light">
                我的點數{" "}
                <span className="text-gray-900 font-bold">
                  {userData.credit || 0}
                </span>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                  openDialog({
                    view: DialogViewEnum.PurchaseCreditDialog,
                    neededCredit: 1,
                  });
                }}
              >
                <CirclePlusIcon />
              </button>
            </div>
          ) : (
            <div className="text-sm font-light text-gray-700">
              {userRoles[user?.role]}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
