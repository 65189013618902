import { Disclosure } from "@headlessui/react";

import type { FCWithChildren } from "koljobs-types";

import CloseIcon from "@/icons/close.svg";
import MenuIcon from "@/icons/menu.svg";

export const PublicNavMobileMenuButton: FCWithChildren<{
  open: boolean;
}> = ({ open }) => {
  return (
    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-500 focus:outline-none">
      <span className="sr-only">Open menu</span>
      {!!open ? (
        <CloseIcon width={20} height={20} />
      ) : (
        <MenuIcon width={20} height={20} />
      )}
    </Disclosure.Button>
  );
};
