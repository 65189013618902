import { useFormContext } from "react-hook-form";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Select } from "@/features/common/components/form/Select";
import { TextField } from "@/features/common/components/form/TextField";
import { EditProfileInputs } from "@/features/model/types/EditProfileInputs";
import {
  CupSizeEnum,
  GenderEnum,
  ModelFragment,
} from "@/generated/graphql-hooks";

export const ModelBodyFields = () => {
  const { user } = useUserContext();
  const {
    register,
    formState: { errors },
  } = useFormContext<EditProfileInputs>();

  return (
    <>
      <div className="grid gap-3 sm:grid-cols-3 sm:gap-4">
        <TextField
          label="身高"
          type="number"
          inputMode="decimal"
          subfix="cm"
          errorMessage={errors?.height?.set?.message}
          {...register("height.set", {
            valueAsNumber: true,
          })}
        />
        <TextField
          label="體重"
          type="number"
          inputMode="decimal"
          subfix="kg"
          errorMessage={errors?.weight?.set?.message}
          {...register("weight.set", {
            valueAsNumber: true,
          })}
        />
        <TextField
          label="鞋碼"
          type="number"
          inputMode="decimal"
          subfix="EU"
          errorMessage={errors?.shoeSize?.set?.message}
          {...register("shoeSize.set", {
            valueAsNumber: true,
          })}
        />
      </div>
      <div className="grid gap-3 sm:grid-cols-3 sm:gap-4">
        {(user?.roleData as ModelFragment)?.gender === GenderEnum.Female ? (
          <div className="relative flex items-end">
            <div className="flex-1">
              <TextField
                label="胸圍"
                type="number"
                inputMode="decimal"
                inputClassName="!rounded-r-none"
                errorMessage={errors?.chest?.set?.message}
                {...register("chest.set", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <Select
              required={false}
              placeholder="Cup"
              placeholderProps={{ disabled: false }}
              className="!rounded-l-none !border-l-0 text-center focus:!border-l"
              options={Object.values(CupSizeEnum).map((cupSize) => ({
                value: cupSize,
                label: cupSize,
              }))}
              {...register("cupSize.set")}
            />
          </div>
        ) : (
          <TextField
            label="上圍"
            type="number"
            inputMode="decimal"
            subfix="inch"
            errorMessage={errors?.chest?.set?.message}
            {...register("chest.set", {
              valueAsNumber: true,
            })}
          />
        )}

        <TextField
          label="腰圍"
          type="number"
          inputMode="decimal"
          subfix="inch"
          errorMessage={errors?.waist?.set?.message}
          {...register("waist.set", {
            valueAsNumber: true,
          })}
        />
        <TextField
          label="臀圍"
          type="number"
          inputMode="decimal"
          subfix="inch"
          errorMessage={errors?.hips?.set?.message}
          {...register("hips.set", {
            valueAsNumber: true,
          })}
        />
      </div>
    </>
  );
};
