import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Checkbox } from "@/features/common/components/form/Checkbox";
import { SettingSectionCard } from "@/features/modelNotificationSettings/components/SettingSectionCard";

const day = {
  星期一: "1",
  星期二: "2",
  星期三: "3",
  星期四: "4",
  星期五: "5",
  星期六: "6",
  星期日: "0",
};

export const JobTimeSection = () => {
  const { register, watch, setValue } = useFormContext();
  const jobTimes = watch("jobTimes.set");

  const isSelectedAll = useMemo(
    () => jobTimes?.length === Object.keys(day).length,
    [jobTimes],
  );

  return (
    <SettingSectionCard title="接受工作時間 (時計工作)">
      <Checkbox
        className="w-max"
        labelClassName="font-light"
        variant="square"
        label="所有時間"
        onChange={() => {
          setValue("jobTimes.set", isSelectedAll ? [] : Object.values(day));
        }}
        checked={isSelectedAll}
      />

      {Object.entries(day).map(([label, value], index) => (
        <Checkbox
          className="w-max"
          labelClassName="font-light"
          key={index}
          variant="square"
          label={label}
          value={value}
          checked={jobTimes.includes(value)}
          {...register(`jobTimes.set`)}
        />
      ))}
    </SettingSectionCard>
  );
};
