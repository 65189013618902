import { useRouter } from "next/router";

import type { FCWithChildren } from "koljobs-types";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Footer } from "@/features/layout/components/Footer";
import { Navbar } from "@/features/layout/components/Navbar";
import { BackableNavBar } from "@/features/layout/components/mobile/BackableNavBar";
import { MobileBottomNavBar } from "@/features/layout/components/mobile/MobileBottomNavBar";
import { ModelProfileCompletionAlert } from "@/features/model/components/ModelProfileCompletionAlert";

export const SiteLayout: FCWithChildren<{
  mobileLayout?: boolean;
  title?: string;
}> = ({ children, mobileLayout = false, title }) => {
  const { pathname } = useRouter();
  const { user } = useUserContext();

  return (
    <div className="flex min-h-screen flex-col justify-between">
      {mobileLayout && (
        <div className="sticky top-0 z-50 sm:hidden">
          <BackableNavBar title={title} />
        </div>
      )}

      <Navbar mobileLayout={mobileLayout} />

      <main className="bg-secondary relative flex flex-1 flex-col">
        {user?.roleData?.__typename === "Model" &&
          !user.roleData.profileCompleted &&
          pathname.includes("/job") && <ModelProfileCompletionAlert />}
        {children}
      </main>

      <div className={mobileLayout ? "hidden sm:block" : ""}>
        <Footer />
      </div>

      <div className="sticky z-50 bottom-0 sm:hidden">
        {user && <MobileBottomNavBar />}
      </div>
    </div>
  );
};
