import { useRouter } from "next/router";
import { useMemo } from "react";

import { BusinessEventEnum, HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { useFirebaseUserContext } from "@/features/auth/contexts/useFirebaseUserContext";
import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Avatar } from "@/features/common/components/Avatar";
import { Dropdown } from "@/features/common/components/Dropdown";
import {
  DialogViewEnum,
  useDialogContext,
} from "@/features/common/contexts/useDialogContext";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";
import { dropdownNavigationItems } from "@/features/layout/consts/dropdownNavigationItems";
import { Business, Model, UserRoleEnum } from "@/generated/graphql-hooks";
import ArrowDownIcon from "@/icons/arrow_down.svg";

export const UserDropdown = () => {
  const { trackEvent } = useAnalytics();
  const { openDialog } = useDialogContext();
  const { signOut } = useFirebaseUserContext();
  const { user } = useUserContext();
  const { push } = useRouter();

  const avatarPublicId = useMemo(() => {
    if (user?.roleData?.__typename === "Business") {
      return user.roleData.logoPublicId;
    }
    if (user?.roleData?.__typename === "Model") {
      return user.roleData.avatarPublicId;
    }
    return null;
  }, [user]);

  const dropdownOptions = useMemo(
    () => [
      ...(user?.roleData?.__typename === "Business"
        ? [
            {
              className: "text-primary hover:text-primary",
              name: "購買點數",
              onClick: () => {
                openDialog({ view: DialogViewEnum.PurchaseCreditDialog });
              },
            },
          ]
        : []),

      ...(user?.roleData?.__typename
        ? [
            {
              name: "我的檔案",
              onClick: () => {
                if (user.roleData.__typename === "Agent") return;
                push(
                  `/${
                    user.roleData.__typename === "Business" ? "business" : "kol"
                  }/${user.roleData.id}/${user.roleData.username}`,
                );
              },
            },
          ]
        : []),

      ...(user?.roleData?.__typename === "Business"
        ? [
            {
              name: "我的帳單",
              onClick: () => {
                trackEvent(BusinessEventEnum.CLICK_PAYMENT_LOG_PAGE);
                push("/business/paymentLog");
              },
            },
          ]
        : []),

      ...dropdownNavigationItems
        .filter((navItem) => !navItem?.role || navItem?.role === user?.role)
        ?.map((navItem) => ({
          name: navItem.name,
          onClick: () => {
            trackEvent(navItem.event);
            push(navItem.paths?.[0]?.path);
          },
        })),
    ],
    [user?.roleData, user?.role],
  );

  return (
    <div className="relative z-50">
      <Dropdown
        button={() => (
          <button className="flex items-center space-x-3">
            <div className="text-sm">
              <Avatar
                publicId={avatarPublicId}
                name={(user?.roleData as Model | Business)?.username}
              />
            </div>
            <ArrowDownIcon width={15} height={15} className="text-gray-500" />
          </button>
        )}
        header={
          user?.role === UserRoleEnum.Business && (
            <div className="bg-primary flex flex-col items-center justify-center rounded-t-md py-2 text-base text-white">
              <span>剩餘點數</span>
              <span>{(user?.roleData as Business)?.credit ?? 0}</span>
            </div>
          )
        }
        options={[
          ...dropdownOptions,
          {
            name: "登出",
            onClick: () => {
              trackEvent(HeaderEventEnum.CLICK_SIGN_OUT);
              signOut();
            },
          },
        ]}
      />
    </div>
  );
};
