import { ModelEventEnum } from "@/consts/AnalyticsEvent";
import { EditProfileForm } from "@/features/model/components/editProfile/EditProfileForm";
import { NotificationSettingsForm } from "@/features/modelNotificationSettings/components/NotificationSettingsForm";
import { EditProfileTab } from "@/features/user/components/EditProfileTabs";
import { EditUserForm } from "@/features/user/components/EditUserForm";

export const modelEditProfileTabs: EditProfileTab[] = [
  {
    label: "個人資料",
    path: "/kol/editProfile",
    component: <EditProfileForm />,
    event: ModelEventEnum.CLICK_EDIT_PROFILE_TAB,
  },
  {
    label: "編輯帳戶",
    path: "/kol/editProfile/user",
    component: <EditUserForm />,
    event: ModelEventEnum.CLICK_EDIT_USER_TAB,
  },
  {
    label: "接收通知",
    path: "/kol/editProfile/notification",
    component: <NotificationSettingsForm />,
    event: ModelEventEnum.CLICK_EDIT_NOTIFICATION_SETTINGS_TAB,
  },
  {
    label: "公開相簿",
    path: "/kol/editPublicAlbum",
    component: null,
    event: ModelEventEnum.CLICK_EDIT_PUBLIC_ALBUM,
  },
  {
    label: "隱藏相簿",
    path: "/kol/privateAlbum",
    component: null,
    event: ModelEventEnum.CLICK_EDIT_PRIVATE_ALBUM,
  },
];
