import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { minFee } from "koljobs-constants";

import { PhoneField } from "@/features/auth/components/PhoneField";
import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { BusinessProfileRewardAlert } from "@/features/business/components/BusinessProfileRewardAlert";
import { EditBusinessProfileFormInputs } from "@/features/business/types/EditBusinessProfileFormInputs";
import { editBusinessProfileFormYupResolver } from "@/features/business/yup/editBusinessProfileFormYupResolver";
import { Button } from "@/features/common/components/Button";
import { Divider } from "@/features/common/components/Divider";
import { Title } from "@/features/common/components/Title";
import { CardFormContainer } from "@/features/common/components/form/CardFormContainer";
import { SingleImageUploadField } from "@/features/common/components/form/SingleImageUploadField";
import { TextField } from "@/features/common/components/form/TextField";
import { useErrorHandling } from "@/features/common/hooks/useErrorHandling";
import { useInvalidateQueries } from "@/features/common/hooks/useInvalidateQueries";
import {
  Business,
  CreditActionEnum,
  UpdateBusinessMutation,
  useUpdateBusinessMutation,
} from "@/generated/graphql-hooks";
import { uploadImages } from "@/services/cloudinary/uploadImages";

export const EditBusinessProfileForm = () => {
  const { user } = useUserContext();
  const { handleGraphqlError } = useErrorHandling();
  const { mutateAsync: updateBusiness } = useUpdateBusinessMutation();
  const { invalidateQueries } = useInvalidateQueries();

  const form = useForm<EditBusinessProfileFormInputs>({
    mode: "onChange",
    resolver: editBusinessProfileFormYupResolver,
  });

  const onUpdateBusiness = async (data) => {
    if (!user) return;

    const { logo, defaultLogo, email, countryCode, phone, ...inputs } = data;
    const uploadedLogo = !!logo?.[0] && (await uploadImages(logo));

    try {
      const result = (await updateBusiness({
        data: inputs,
        ...(!!uploadedLogo?.[0] && { logo: uploadedLogo[0] }),
        email,
        phone: !!phone ? `+${countryCode}${phone}` : null,
      })) as UpdateBusinessMutation;

      invalidateQueries(["JobWithBusinessPrivateInfo", "User"]);

      toast.success(
        `成功更新商戶資料${
          result?.updateBusiness?.action ===
          CreditActionEnum.BusinessProfileCompletionReward
            ? `，已饋贈${minFee}點數到帳戶`
            : ""
        }`,
      );
    } catch (error) {
      handleGraphqlError(error, {
        tags: {
          action: "EditBusinessProfileForm.onUpdate",
        },
      });
    }
  };

  useEffect(() => {
    if (!user?.roleData) return;
    const { username, logoPublicId, privateInfo } = user.roleData as Business;
    form.reset({
      username: { set: username },
      defaultLogo: logoPublicId,
      email: privateInfo?.email,
      ...(!!privateInfo?.phone && {
        countryCode: parseInt(privateInfo.phone.substring(1, 4)),
        phone: privateInfo.phone.substring(4, privateInfo.phone.length),
      }),
    });
  }, [user?.roleData]);

  return (
    <CardFormContainer>
      {!(user?.roleData as Business)?.isRewarded && (
        <BusinessProfileRewardAlert />
      )}

      <Title size="h2" className="text-gray-900">
        個人資料
      </Title>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onUpdateBusiness)}
          className="space-y-5"
        >
          <div className="space-y-3">
            <Controller
              name="logo"
              render={({ field: { ref, ...props }, formState: { errors } }) => (
                <SingleImageUploadField
                  required
                  label="頭像"
                  publicId={(user?.roleData as Business)?.logoPublicId}
                  errorMessage={errors?.logo?.message}
                  {...props}
                />
              )}
            />
            <TextField
              required
              label="顯示名稱"
              errorMessage={form.formState?.errors?.username?.set?.message}
              {...form.register("username.set")}
            />
          </div>
          <div className="py-3">
            <Divider />
          </div>

          <Title size="h2" className="text-gray-900">
            隱藏資料
          </Title>
          <div className="text-base font-light text-gray-900">
            以下隱藏資料只供成功配對的KOL查看，當通告完結後KOL便不能查看。
          </div>

          <div className="grid gap-3 sm:grid-cols-2 sm:gap-4">
            <PhoneField label="工作電話" />

            <TextField
              required
              label="工作電郵"
              type="email"
              errorMessage={form.formState.errors?.email?.message}
              {...form.register("email")}
            />
          </div>

          <div className="flex justify-end pt-4">
            <Button
              className="sm:w-max"
              type="submit"
              isLoading={form.formState.isSubmitting}
            >
              確定
            </Button>
          </div>
        </form>
      </FormProvider>
    </CardFormContainer>
  );
};
