import clsx from "clsx";

import type { FCWithChildren } from "koljobs-types";

export const Container: FCWithChildren<{
  className?: string;
  fullInMobile?: boolean;
}> = ({ className, fullInMobile, children }) => {
  return (
    <div
      className={clsx(
        "mx-auto w-full sm:max-w-screen-sm sm:px-8 md:max-w-screen-md lg:max-w-screen-lg lg:px-16 xl:max-w-screen-xl xl:px-20 2xl:max-w-screen-2xl 2xl:px-24",
        fullInMobile ? "px-0" : "px-4",
        className,
      )}
    >
      {children}
    </div>
  );
};
