import clsx from "clsx";
import Link from "next/link";

import { HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";

export type SignUpButtonProps = {
  onNavbar?: boolean;
};

export const SignUpButton = ({ onNavbar }: SignUpButtonProps) => {
  const { trackEvent } = useAnalytics();

  return (
    <div className="flex items-center">
      <Link
        href="/signup"
        passHref
        className={clsx(
          "h-max flex-1 whitespace-nowrap rounded px-2 text-center text-base",
          !!onNavbar
            ? "py-1 font-light text-gray-700 hover:bg-gray-200"
            : "text-primary py-2",
        )}
        onClick={() => trackEvent(HeaderEventEnum.CLICK_SIGN_UP)}
      >
        註冊
      </Link>
    </div>
  );
};
