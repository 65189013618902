import Link from "next/link";

import { getPublicConfig } from "@/helpers/getPublicConfig";
import FacebookIcon from "@/icons/footer/facebook.svg";
import InstagramIcon from "@/icons/footer/instagram.svg";

const {
  project: { companyName },
  socialMedia: { facebook, instagram },
} = getPublicConfig();

export const Footer = () => {
  return (
    <footer className="flex flex-col items-center justify-center space-y-1 bg-gray-200 py-5 px-4 text-center text-sm font-light text-gray-900">
      <span className="flex items-center space-x-1">
        <a
          href={`https://www.facebook.com/${facebook}/`}
          className="text-gray-600 hover:opacity-70"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href={`https://www.instagram.com/${instagram}/`}
          className="pl-1 text-gray-600 hover:opacity-70"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </a>
        <div className="px-1">
          <div className="h-5 w-[1px] bg-gray-300" />
        </div>
        <Link
          href="/termsOfService"
          passHref
          className="font-normal hover:opacity-70"
        >
          服務條款
        </Link>
        <span>和</span>
        <Link
          href="/privacyPolicy"
          passHref
          className="font-normal hover:opacity-70"
        >
          隱私政策
        </Link>
      </span>
      <span>{`版權所有 © ${new Date().getFullYear()} ${companyName} | 職業介紹所牌照 76011`}</span>
    </footer>
  );
};
