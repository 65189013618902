import Link from "next/link";

import { HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";
import { MobileSideNavItemType } from "@/features/layout/types/MobileSideNavItemType";

type MobileSideMenuItemProps = {
  item: MobileSideNavItemType;
  onClick: () => void;
};
export const MobileSideMenuItem = ({
  item,
  onClick,
}: MobileSideMenuItemProps) => {
  const { trackEvent } = useAnalytics();

  return (
    <Link
      href={item.path}
      passHref
      className="px-4 py-2 text-gray-900 hover:bg-gray-100 text-left"
      onClick={() => {
        trackEvent(HeaderEventEnum.CLICK_NAV_ITEM, { to: item.path });
        onClick();
      }}
    >
      {item.name}
    </Link>
  );
};
