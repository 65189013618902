import clsx from "clsx";
import { useMemo } from "react";

export const Ping = ({
  className,
  size = "sm",
  color = "primary",
}: {
  className?: string;
  size?: "sm" | "md" | "lg";
  color?: "primary" | "red";
}) => {
  const sizes = useMemo(() => {
    if (size === "sm") return "h-2 w-2";
    if (size === "md") return "h-3 w-3";
    return "h-4 w-4";
  }, [size]);

  const colorClassName = useMemo(() => {
    if (color === "primary") return "bg-primary";
    return "bg-red-500";
  }, [color]);

  return (
    <span className={clsx("flex", sizes, className)}>
      <span
        className={clsx(
          "bg-primary absolute inline-flex h-full w-full animate-ping rounded-full opacity-75",
          colorClassName,
        )}
      ></span>
      <span
        className={clsx(
          "bg-primary relative inline-flex rounded-full",
          sizes,
          colorClassName,
        )}
      ></span>
    </span>
  );
};
