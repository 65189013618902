import toast from "react-hot-toast";

import { useFirebaseUserContext } from "@/features/auth/contexts/useFirebaseUserContext";
import { runIfNotTestUser } from "@/features/auth/helpers/runIfNotTestUser";
import { Button } from "@/features/common/components/Button";
import { FieldBaseContainer } from "@/features/common/components/form/FieldBaseContainer";
import {
  useConfirmDialogContext,
  withConfirmDialog,
} from "@/features/common/contexts/useConfirmDialogContext";
import { useErrorHandling } from "@/features/common/hooks/useErrorHandling";
import { useDeleteUserMutation } from "@/generated/graphql-hooks";

export const DeleteUserButtonField = withConfirmDialog(() => {
  const { handleError } = useErrorHandling();
  const { openConfirmDialog } = useConfirmDialogContext();
  const { mutateAsync: deleteUser, isLoading } = useDeleteUserMutation();
  const { firebaseUser, signOut } = useFirebaseUserContext();

  const onDelete = async () => {
    try {
      await deleteUser({});
      await signOut();
      toast.success("成功刪除帳戶");
    } catch (error) {
      handleError(error, {
        tags: { action: "DeleteUserButtonField.onDelete" },
      });
    }
  };

  return (
    <FieldBaseContainer label="刪除你的資料和帳戶">
      <Button
        variant="light"
        isLoading={isLoading}
        onClick={() => {
          runIfNotTestUser(firebaseUser?.uid, () =>
            openConfirmDialog({
              content: "確定要刪除帳戶嗎？",
              onConfirm: onDelete,
            }),
          );
        }}
      >
        刪除帳戶
      </Button>
    </FieldBaseContainer>
  );
});
