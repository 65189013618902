import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { emailFieldSchema } from "@/features/auth/yup/schema/emailFieldSchema";

export const editBusinessProfileFormYupResolver = yupResolver(
  emailFieldSchema.concat(
    yup.object().shape({
      defaultLogo: yup.string().nullable(),
      logo: yup.mixed().when("defaultLogo", {
        is: (defaultLogo) => !defaultLogo,
        then: yup.mixed().required("請選擇頭像"),
      }),
      username: yup.object({
        set: yup.string().required("請輸入顯示名稱"),
      }),
      phone: yup
        .string()
        .test(
          "empty-or-8-characters-check",
          "請輸入8位數字的電話號碼",
          (phone) => !phone || phone?.length === 8,
        ),
    }),
  ),
);
