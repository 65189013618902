import { useMemo } from "react";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Ping } from "@/features/common/components/Ping";
import { Popover } from "@/features/common/components/Popover";
import { NotificationList } from "@/features/notification/components/NotificationList";
import { useNotificationQuery } from "@/generated/graphql-hooks";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import BellIcon from "@/icons/bell.svg";

const {
  features: {
    notification: { pollInterval },
  },
} = getPublicConfig();

export const NotificationButton = () => {
  const { user } = useUserContext();
  const { data: { notification } = {} } = useNotificationQuery(
    {},
    { enabled: !!user, refetchInterval: pollInterval },
  );

  const hasUnreadNotification = useMemo(() => {
    if (!notification?.createdAt) return false;
    return (
      Date.parse(notification.createdAt) > Date.parse(user?.lastReadAt ?? 0)
    );
  }, [notification, user]);

  return (
    <Popover
      button={
        <div className="relative flex items-center">
          <BellIcon className="cursor-pointer" />
          {hasUnreadNotification && <Ping className="absolute top-0 right-0" color="red"/>}
        </div>
      }
    >
      <NotificationList />
    </Popover>
  );
};
