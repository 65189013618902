import clsx from "clsx";
import Link from "next/link";

import type { FCWithChildren } from "koljobs-types";

import { HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";

export const NavItem: FCWithChildren<{
  active?: boolean;
  label: string;
  path: string;
}> = ({ active = false, label, path }) => {
  const { trackEvent } = useAnalytics();
  return (
    <Link
      href={path}
      passHref
      className={clsx(
        active
          ? "text-primary border-primary"
          : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-500",
        "inline-flex items-center whitespace-nowrap border-b-4 px-3 pt-1 text-base font-light",
      )}
      onClick={() => trackEvent(HeaderEventEnum.CLICK_NAV_ITEM, { to: path })}
    >
      {label}
    </Link>
  );
};
