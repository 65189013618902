import { Controller, useFormContext } from "react-hook-form";

import { Toggle } from "@/features/common/components/Toggle";
import { SettingSectionCard } from "@/features/modelNotificationSettings/components/SettingSectionCard";

export const EnableNotificationSection = () => {
  const { control } = useFormContext();

  return (
    <SettingSectionCard title="通知設定" gridColumns="full">
      <Controller
        control={control}
        name="enableNotification"
        render={({ field: { value, onChange } }) => (
          <Toggle
            className="justify-between"
            label="有相關通告立即通知我"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </SettingSectionCard>
  );
};
