import clsx from "clsx";
import { forwardRef, InputHTMLAttributes, ReactNode } from "react";

import CheckIcon from "@/icons/check.svg";

export type CheckboxProps = {
  variant?: "square" | "circle" | "none" | "custom";
  label: ReactNode;
  checked?: boolean;
  selectAll?: boolean;
  labelClassName?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      variant = "circle",
      label,
      name,
      value,
      onChange,
      checked,
      className,
      selectAll = false,
      labelClassName,
      ...props
    },
    ref,
  ) => {
    if (variant === "custom") {
      return (
        <label className="relative h-full w-full">
          {!props.disabled && (
            <>
              <div
                className={clsx(
                  "absolute top-2 left-2 z-10 rounded-full border border-white p-1",
                  checked
                    ? "bg-primary hover:bg-opacity-90 focus:bg-opacity-90 "
                    : "bg-black bg-opacity-50 hover:bg-opacity-70 focus:bg-opacity-70 ",
                )}
              >
                <CheckIcon className="h-2.5 w-2.5 text-white md:h-4 md:w-4" />
              </div>
              <input
                className="hidden"
                type="checkbox"
                name={name}
                value={value}
                onChange={onChange}
                ref={ref}
                checked={checked}
              />
            </>
          )}

          <div className={!props.disabled ? "pointer-events-none" : ""}>
            {label}
          </div>
        </label>
      );
    }

    return (
      <label
        className={clsx(
          "flex cursor-pointer items-center space-x-3",
          className,
        )}
      >
        {variant !== "none" && (
          <div
            className={clsx(
              checked ? "bg-primary" : "bg-gray-300",
              variant === "circle" ? "h-6 w-6 rounded-full" : "h-4 w-4 rounded",
              "relative flex items-center justify-center",
            )}
          >
            <CheckIcon
              className="pointer-events-none text-white"
              width={variant === "circle" ? 16 : 10}
              height={variant === "circle" ? 11 : 10}
            />
          </div>
        )}

        <div className={clsx("flex items-center", labelClassName)}>{label}</div>

        <input
          className="hidden"
          type="checkbox"
          name={name}
          value={value}
          onChange={onChange}
          ref={ref}
          checked={checked}
        />
      </label>
    );
  },
);
