import clsx from "clsx";
import type { DetailedHTMLProps, HTMLAttributes } from "react";

import type { FCWithChildren } from "koljobs-types";

export type TitleProps = {
  size?: "h1" | "h2" | "h3" | "h4";
} & DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

const sizeClassName = {
  h1: "text-3xl sm:text-5xl",
  h2: "text-2xl sm:text-3xl",
  h3: "text-xl sm:text-2xl",
  h4: "text-lg sm:text-xl",
};

export const Title: FCWithChildren<TitleProps> = ({
  size = "h1",
  className,
  ...props
}) => {
  const Component = size;
  return (
    <Component className={clsx(sizeClassName[size], className)} {...props} />
  );
};
