import { useMemo } from "react";

import {
  DEFAULT_ERROR_MESSAGE,
  ErrorMessages,
} from "koljobs-constants/src/error";

export const useGraphqlError = ({ error }: { error: unknown }) => {
  // @todo - get eventId of event captured in server. Currently not possible because graphql codegen only throws message of server error
  const serverSentryEventId = useMemo(() => null, []);

  const errorMessage = useMemo(() => {
    if (!(error instanceof Error)) {
      return DEFAULT_ERROR_MESSAGE;
    }

    return (
      ErrorMessages[error.message] ??
      `${DEFAULT_ERROR_MESSAGE} (${error.message})`
    );
  }, [error]);

  return useMemo(
    () => ({
      serverSentryEventId,
      errorMessage,
    }),
    [serverSentryEventId, errorMessage],
  );
};
