import { IconButton } from "@/features/common/components/IconButton";
import {
  DialogViewEnum,
  useDialogContext,
} from "@/features/common/contexts/useDialogContext";
import { ModelRatingDialogViewProps } from "@/features/job/components/manage/ModelRatingDialogView";
import RateIcon from "@/icons/manageJob/rate.svg";

export const RateModelDialogButton = ({
  jobId,
}: ModelRatingDialogViewProps) => {
  const { openDialog } = useDialogContext();

  return (
    <IconButton
      className="flex items-center"
      onClick={() => {
        openDialog({
          jobId,
          view: DialogViewEnum.ModelRatingDialog,
        });
      }}
    >
      <RateIcon />
    </IconButton>
  );
};
