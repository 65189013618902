import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";
import { mobileBottomNavigationItems } from "@/features/layout/consts/mobileBottomNavigationItems";
import { cn } from "@/lib/utils";

import { MobileSideMenu } from "./MobileSideMenu";

export const MobileBottomNavBar = () => {
  const { pathname } = useRouter();
  const { user } = useUserContext();
  const { trackEvent } = useAnalytics();
  const navItems = useMemo(
    () =>
      mobileBottomNavigationItems.filter(
        (navItem) => !navItem?.role || navItem?.role === user?.role,
      ),
    [user?.role],
  );

  const avatarPublicId = useMemo(() => {
    if (user?.roleData?.__typename === "Business") {
      return user.roleData.logoPublicId;
    }
    if (user?.roleData?.__typename === "Model") {
      return user.roleData.avatarPublicId;
    }
    return null;
  }, [user?.roleData]);

  return (
    <div className="overflow-x-scroll bg-white border-t pb-[env(safe-area-inset-bottom)] grid grid-flow-col">
      {navItems?.map((navItem, index) => (
        <Link
          href={navItem.path}
          passHref
          onClick={() =>
            trackEvent(HeaderEventEnum.CLICK_NAV_ITEM, { to: navItem.path })
          }
          className={cn(
            navItem.path === pathname
              ? "text-gray-900 font-medium"
              : "text-gray-500",
            "flex pt-0.5 flex-col items-center gap-0.5 text-xs flex-shrink-0 active:bg-gray-100",
          )}
        >
          {navItem.icon}
          {navItem.name}
        </Link>
      ))}
      <MobileSideMenu />
    </div>
  );
};
