import { twConfig } from "@/helpers/getTailwindConfig";

export const tailwindScreenSizes = Object.entries(
  twConfig.theme.screens as Record<string, string>,
).reduce(
  (sizes, [key, value]) => ({
    ...sizes,
    [key]: parseInt(value.slice(0, value.indexOf("px"))),
  }),
  {} as {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  },
);
