import { SignInButton } from "@/features/auth/components/signIn/SignInButton";
import { SignUpButton } from "@/features/auth/components/signUp/SignUpButton";

export const PublicNavMobileMenuHeader = () => {
  return (
    <div className="bg-secondary py-3">
      <div className="mx-14 space-y-4">
        <SignInButton />
        <SignUpButton />
      </div>
    </div>
  );
};
