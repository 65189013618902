import toast from "react-hot-toast";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const { testUserIds } = getPublicConfig();

export const runIfNotTestUser = (
  id: string,
  callback: () => void | Promise<void>,
) => {
  if (testUserIds.includes(id)) {
    toast.error("測試帳戶不能進行此動作");
    return;
  }
  callback();
};
