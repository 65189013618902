import clsx from "clsx";
import { useMemo } from "react";

import { jobTypeIcons, jobTypes } from "@/features/job/const/jobTypes";
import { JobTypeEnum } from "@/generated/graphql-hooks";
import AllIcon from "@/icons/job/all.svg";

export type JobTypeBadgeProps = {
  size?: "sm" | "md";
  className?: string;
  labelClassName?: string;
  minimize?: boolean;
  jobType: JobTypeEnum;
  fallbackLabel?: string;
};

export const JobTypeBadge = ({
  size = "sm",
  className,
  labelClassName,
  minimize = false,
  jobType,
  fallbackLabel = "所有",
}: JobTypeBadgeProps) => {
  const JobIcon = useMemo(() => {
    if (!jobType) return AllIcon;
    return jobTypeIcons[jobType];
  }, [jobType]);

  return (
    <span
      className={clsx(
        minimize
          ? "bg-transparent"
          : "space-x-1 rounded-[5px] bg-gray-100 px-2.5 py-1.5 text-sm font-normal text-gray-700",
        "inline-flex items-center whitespace-nowrap",
        className,
      )}
    >
      <JobIcon
        width={size === "sm" ? 20 : 25}
        height={size === "sm" ? 20 : 25}
      />
      {!minimize && (
        <span className={labelClassName}>
          {jobTypes[jobType] ?? fallbackLabel}
        </span>
      )}
    </span>
  );
};
