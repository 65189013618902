import { useMemo } from "react";

import { useUserContext } from "@/features/auth/contexts/useUserContext";

export const useUserData = () => {
  const { user } = useUserContext();
  const userData = useMemo(() => {
    if (user?.roleData?.__typename === "Business") {
      return {
        avatar: user.roleData.logoPublicId,
        username: user.roleData.username,
        credit: user.roleData.credit,
        path: `/business/${user.roleData.id}/${user.roleData.username}`,
      };
    }
    if (user?.roleData?.__typename === "Model") {
      return {
        avatar: user.roleData.avatarPublicId,
        username: user.roleData.username,
        path: `/kol/${user.roleData.id}/${user.roleData.username}`,
      };
    }
    return null;
  }, [user?.roleData]);

  return userData;
};
