import { useMemo } from "react";
import Select from "react-select";

import {
  FieldBaseContainer,
  FieldBaseContainerProps,
} from "@/features/common/components/form/FieldBaseContainer";
import { twConfig } from "@/helpers/getTailwindConfig";

const selectAllOption = {
  value: "*",
  label: "所有",
};

type OptionType = {
  label: string;
  value: string | number;
};

type MultiSelectProps<T> = {
  value?: T;
  options: OptionType[];
  placeholder?: string;
  onChange: (item: T) => void;
} & FieldBaseContainerProps;

export const MultiSelect = <T extends (string | number)[]>({
  value,
  options,
  placeholder,
  errorMessage,
  onChange,
  ...props
}: MultiSelectProps<T>) => {
  const isOptionSelected = (option) =>
    value?.some((val) => val === option.value) ||
    value?.length === options.length;

  const onSelect = (item: OptionType[]) => {
    const value = Array.from(item, (option: OptionType) => option.value) as T;

    if (value.includes(selectAllOption.value)) {
      onChange(Array.from(options, (option: OptionType) => option.value) as T);
    } else {
      onChange(value);
    }
  };

  const customStyles = useMemo(() => {
    const errorControlStyles = {
      borderColor: `${twConfig.theme.colors.red["300"]} !important`,
      background: `${twConfig.theme.colors.red["50"]} !important`,
    };

    return {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? twConfig.theme.colors.primary
          : `${twConfig.theme.colors.gray["300"]} !important`,
        borderRadius: 6,
        boxShadow: "none !important",
        padding: 2,
        ...(!!errorMessage && errorControlStyles),
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: twConfig.theme.fontSize.base[0],
        color: twConfig.theme.colors.gray["500"],
      }),
      option: (base) => ({
        ...base,
        background: "transparent",
      }),
    };
  }, [errorMessage]);

  return (
    <FieldBaseContainer {...props} errorMessage={errorMessage}>
      <Select
        id={props.name}
        instanceId={props.name}
        placeholder={placeholder}
        styles={customStyles}
        isOptionSelected={isOptionSelected}
        value={options.filter((option) => value?.includes(option.value))}
        name={props.name}
        options={[selectAllOption, ...options]}
        onChange={onSelect}
        isMulti
        isSearchable={false}
      />
    </FieldBaseContainer>
  );
};
