import Image from "next/legacy/image";
import { useRouter } from "next/router";

import { HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";
import { getPublicConfig } from "@/helpers/getPublicConfig";

const {
  project: { siteName },
} = getPublicConfig();

export const Logo = () => {
  const { trackEvent } = useAnalytics();
  const { push } = useRouter();

  return (
    <>
      <div className="flex h-8 w-auto cursor-pointer items-center md:hidden">
        <Image
          alt={siteName}
          src="/images/logo_mobile.png"
          layout="fixed"
          width={22}
          height={28}
          onClick={() => {
            push("/");
            trackEvent(HeaderEventEnum.CLICK_LOGO, {
              device: "mobile",
            });
          }}
          priority
          unoptimized
        />
      </div>
      <div className="hidden h-4 w-36 cursor-pointer items-center md:block lg:h-8 lg:w-52">
        <Image
          alt={siteName}
          src="/images/logo.png"
          layout="responsive"
          width={201}
          height={30}
          onClick={() => {
            push("/");
            trackEvent(HeaderEventEnum.CLICK_LOGO, {
              device: "desktop",
            });
          }}
          priority
          unoptimized
        />
      </div>
    </>
  );
};
