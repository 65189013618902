import { Disclosure } from "@headlessui/react";
import { Navbar, ScrollArea } from "@mantine/core";

import { PublicNavMobileItem } from "@/features/layout/components/mobile/PublicNavMobileItem";
import { PublicNavMobileMenuHeader } from "@/features/layout/components/mobile/PublicNavMobileMenuHeader";
import { publicMobileNavigationItems } from "@/features/layout/consts/publicMobileNavigationItems";

// mobile navigation menu panel
export const PublicNavMobilePanel = () => {
  return (
    <Disclosure.Panel className="sm:hidden">
      <Navbar className="h-max">
        <Navbar.Section>
          <PublicNavMobileMenuHeader />
        </Navbar.Section>

        <Navbar.Section grow component={ScrollArea}>
          {publicMobileNavigationItems.map((item, index) => (
            <PublicNavMobileItem {...item} key={index} />
          ))}
        </Navbar.Section>
      </Navbar>
    </Disclosure.Panel>
  );
};
