import { Cloudinary } from "@cloudinary/url-gen";
import { format } from "@cloudinary/url-gen/actions/delivery";
import { blur } from "@cloudinary/url-gen/actions/effect";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { auto } from "@cloudinary/url-gen/qualifiers/format";
import Image from "next/legacy/image";
import { useEffect, useMemo, useState } from "react";

import { getPublicConfig } from "@/helpers/getPublicConfig";

type CloudinaryImageProps = {
  alt: string;
  src?: string;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
  publicId?: string;
  className?: string;
  height?: number;
  width?: number;
};

const {
  services: {
    cloudinary: { cloudName },
  },
} = getPublicConfig();

export const CloudinaryImage = ({
  alt = "",
  src,
  objectFit = "cover",
  publicId,
  height,
  width,
  className,
}: CloudinaryImageProps) => {
  const cloudinary = useMemo(() => {
    return new Cloudinary({
      cloud: { cloudName },
    });
  }, [cloudName]);

  const blurredUrl = cloudinary
    .image(publicId)
    .delivery(format(auto()).lossy())
    .effect(blur(2000))
    .resize(scale().width(400))
    .toURL();

  const [cldImg, setCldImg] = useState<string | null>(null);

  useEffect(() => {
    setCldImg(cloudinary.image(publicId).delivery(format(auto())).toURL());
  }, [cldImg, publicId, cloudinary]);

  if (!src && !publicId) return null;

  if (!!src) {
    return (
      <Image
        loader={({ src }) => src}
        alt={alt}
        className={className}
        src={src}
        layout="fill"
        objectFit={objectFit}
        objectPosition="center"
      />
    );
  }

  if (height && width) {
    return (
      <Image
        alt={alt}
        blurDataURL={blurredUrl}
        className={className}
        layout="responsive"
        placeholder="blur"
        src={cldImg ?? blurredUrl}
        height={height}
        width={width}
      />
    );
  }

  return (
    <>
      <Image
        alt={alt}
        className={className}
        src={blurredUrl}
        priority
        layout="fill"
        objectFit={objectFit}
        objectPosition="center"
      />
      {cldImg && (
        <Image
          alt={alt}
          className={className}
          src={cldImg}
          layout="fill"
          objectFit={objectFit}
          objectPosition="center"
        />
      )}
    </>
  );
};
