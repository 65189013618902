import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const editProfileFormYupResolver = yupResolver(
  yup.object().shape({
    defaultAvatar: yup.string().nullable(),
    avatar: yup.mixed().when("defaultAvatar", {
      is: (defaultAvatar) => !defaultAvatar,
      then: yup.mixed().required("請選擇頭像"),
    }),
    username: yup.object({
      set: yup.string().nullable().required("請輸入顯示名稱"),
    }),
    description: yup.object({
      set: yup.string().nullable().required("請輸入簡介"),
    }),
    prefJobType: yup.object({
      set: yup
        .array()
        .min(1, "請選擇最少一項擅長工作")
        .required("請選擇擅長工作"),
    }),
    phone: yup
      .string()
      .test(
        "empty-or-8-characters-check",
        "請輸入8位數字的電話號碼",
        (phone) => !phone || phone?.length === 8,
      ),
    instagram: yup.object({
      set: yup
        .string()
        .transform((value) => value?.trim()?.toLowerCase()?.replace(/\s/g, ""))
        .test(
          "empty-or-30-characters-check",
          "請輸入不多於30個字符",
          (value) => !value || value?.length <= 30,
        ),
    }),
    littleRedBook: yup.object({
      set: yup
        .string()
        .transform((value) => value?.trim()?.toLowerCase()?.replace(/\s/g, ""))
        .test(
          "empty-or-15-characters-check",
          "請輸入不多於15個字符",
          (value) => !value || value?.length <= 15,
        ),
    }),
    youtube: yup.object({
      set: yup
        .string()
        .transform((value) => value?.trim()?.replace(/\s/g, ""))
        .test(
          "empty-or-30-characters-check",
          "請輸入不多於30個字符",
          (value) => !value || value?.length <= 30,
        ),
    }),
  }),
);
