import Router from "next/router";
import { useEffect } from "react";
import { useBeforeUnload } from "react-use";

export const useLeavePageConfirm = (
  isConfirm = true,
  message = "Are you sure want to leave this page?",
) => {
  useBeforeUnload(isConfirm, message);

  useEffect(() => {
    const handler = (url: string, { shallow }: { shallow: boolean }) => {
      if (isConfirm && !window.confirm(message)) {
        Router.events.emit("routeChangeError", "routeChange aborted.", url, {
          shallow,
        });
        throw "routeChange aborted.";
      }
    };

    Router.events.on("routeChangeStart", handler);
    return () => {
      Router.events.off("routeChangeStart", handler);
    };
  }, [isConfirm, message]);
};
