import * as yup from "yup";

import { passwordFieldSchema } from "@/features/auth/yup/schema/passwordFieldSchema";

export const confirmPasswordFieldSchema = passwordFieldSchema.concat(
  yup.object().shape({
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "請輸入一致的密碼")
      .required("請輸入確認密碼"),
  }),
);
