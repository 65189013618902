import { NotificationEventEnum } from "@/generated/graphql-hooks";

export const notificationEvents = {
  [NotificationEventEnum.BusinessAcceptJobApplication]: "接受了你的申請",
  [NotificationEventEnum.BusinessInviteJob]: "向你發出邀請",
  [NotificationEventEnum.BusinessRejectJobApplication]: "已查閱你的申請",
  [NotificationEventEnum.BusinessSendVideoCallRequest]: "向你發出視像面試",
  [NotificationEventEnum.ModelAcceptJobApplication]: "接受了你的邀請",
  [NotificationEventEnum.ModelRejectJobApplication]: "已查閱了你的邀請",
  [NotificationEventEnum.ModelApplyJob]: "已申請你的通告",
  [NotificationEventEnum.SystemRemindBusinessInviteJob]: "系統提醒你評價KOL",
  [NotificationEventEnum.SystemRecommendModelNewJob]:
    "有一則你可能感興趣的通告",
};
