import { BusinessEventEnum } from "@/consts/AnalyticsEvent";
import { EditBusinessProfileForm } from "@/features/business/components/EditBusinessProfileForm";
import { EditProfileTab } from "@/features/user/components/EditProfileTabs";
import { EditUserForm } from "@/features/user/components/EditUserForm";

export const businessEditProfileTabs: EditProfileTab[] = [
  {
    label: "個人資料",
    path: "/business/editProfile",
    component: <EditBusinessProfileForm />,
    event: BusinessEventEnum.CLICK_EDIT_PROFILE_PAGE,
  },
  {
    label: "編輯帳戶",
    path: "/business/editProfile/user",
    component: <EditUserForm />,
    event: BusinessEventEnum.CLICK_EDIT_USER_PAGE,
  },
];
