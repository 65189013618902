import { MainNavItemType } from "@/features/layout/types/MainNavItemType";
import { UserRoleEnum } from "@/generated/graphql-hooks";

export const mainNavigationItems: MainNavItemType[] = [
  { name: "首頁", path: "/" },
  { name: "通告欄", path: "/job" },
  { name: "KOL", path: "/kol" },
  { name: "網誌", path: "/blog" },
  {
    name: "管理通告",
    path: "/business/manageJob",
    role: UserRoleEnum.Business,
  },
  { name: "我的通告", path: "/kol/manageJob", role: UserRoleEnum.Model },
];
