import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Checkbox } from "@/features/common/components/form/Checkbox";
import {
  JobTypeBadge,
  JobTypeBadgeProps,
} from "@/features/job/components/jobList/JobTypeBadge";
import { jobTypes } from "@/features/job/const/jobTypes";
import { SettingSectionCard } from "@/features/modelNotificationSettings/components/SettingSectionCard";
import { JobTypeEnum } from "@/generated/graphql-hooks";

const jobTypeBadgeProps: Partial<JobTypeBadgeProps> = {
  className: "!bg-transparent !space-x-2.5 !px-1 !py-0 !text-gray-500",
  labelClassName: "text-gray-900 text-base font-light",
  size: "md",
};

export const SelectJobTypeSection = () => {
  const { register, watch, setValue } = useFormContext();
  const selectedJobTypes = watch("jobTypes.set");

  const isSelectedAll = useMemo(
    () => selectedJobTypes?.length === Object.keys(jobTypes).length,
    [selectedJobTypes],
  );

  return (
    <SettingSectionCard title="接受工作類型">
      <Checkbox
        className="w-max"
        labelClassName="font-light"
        variant="square"
        label={
          <JobTypeBadge
            jobType={null}
            fallbackLabel="所有類型"
            {...jobTypeBadgeProps}
          />
        }
        onChange={() => {
          setValue("jobTypes.set", isSelectedAll ? [] : Object.keys(jobTypes));
        }}
        checked={isSelectedAll}
      />

      {Object.keys(jobTypes).map((jobType, index) => (
        <Checkbox
          className="w-max"
          variant="square"
          label={
            <JobTypeBadge
              jobType={jobType as JobTypeEnum}
              {...jobTypeBadgeProps}
            />
          }
          value={jobType}
          checked={
            Array.isArray(selectedJobTypes)
              ? selectedJobTypes.includes(jobType)
              : false
          }
          {...register("jobTypes.set")}
          key={index}
        />
      ))}
    </SettingSectionCard>
  );
};
