import clsx from "clsx";
import { useEffect, useState } from "react";

import { CloudinaryImage } from "@/features/common/components/CloudinaryImage";
import { ErrorMessage } from "@/features/common/components/form/ErrorMessage";
import {
  FieldBaseContainer,
  FieldBaseContainerProps,
} from "@/features/common/components/form/FieldBaseContainer";
import UploadPhotoIcon from "@/icons/upload_photo.svg";

type AvatarUploadFieldProps = {
  value: FileList;
  publicId?: string;
  onChange: (...event: any[]) => void;
} & FieldBaseContainerProps;

export const SingleImageUploadField = ({
  name,
  value,
  publicId,
  errorMessage,
  onChange,
  ...props
}: AvatarUploadFieldProps) => {
  const [preview, setPreview] = useState<string | undefined>();

  useEffect(() => {
    if (!value) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(value[0]);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [value]);

  return (
    <FieldBaseContainer {...props} name={name}>
      <div className="flex justify-center">
        <div className="space-y-1">
          <div
            className={clsx(
              "relative h-48 w-48 rounded-[5px] border bg-slate-200",
              !!errorMessage ? "border-red-500" : "",
            )}
          >
            <CloudinaryImage
              alt="image"
              className="rounded-[5px]"
              publicId={publicId}
              src={preview}
            />
            <label
              htmlFor={name}
              className="absolute bottom-0 right-0 block translate-y-1/3 translate-x-1/3 transform rounded-full shadow-md"
            >
              <div className="relative inline-flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white">
                <UploadPhotoIcon />
              </div>
              <input
                id={name}
                name={name}
                type="file"
                className="sr-only"
                onChange={(e) => onChange(e.target.files)}
              />
            </label>
          </div>
          {!!errorMessage && <ErrorMessage message={errorMessage} />}
        </div>
      </div>
    </FieldBaseContainer>
  );
};
