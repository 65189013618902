export const NotificationItemSkeleton = () => {
  return (
    <>
      {Array.from(Array(3)).map((_, index) => (
        <div
          className="flex w-full items-center justify-between px-6 py-4"
          key={index}
        >
          <div className="flex flex-1 space-x-3">
            <div className="flex flex-1 animate-pulse flex-col justify-center space-y-3">
              <span className="h-4 w-full rounded bg-slate-200"></span>
              <span className="h-4 w-3/4 rounded bg-slate-200"></span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
