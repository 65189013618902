import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useFirebaseUserContext } from "@/features/auth/contexts/useFirebaseUserContext";
import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { runIfNotTestUser } from "@/features/auth/helpers/runIfNotTestUser";
import { confirmPasswordFieldSchema } from "@/features/auth/yup/schema/confirmPasswordFieldSchema";
import { Button } from "@/features/common/components/Button";
import { Title } from "@/features/common/components/Title";
import { CardFormContainer } from "@/features/common/components/form/CardFormContainer";
import { TextField } from "@/features/common/components/form/TextField";
import { useErrorHandling } from "@/features/common/hooks/useErrorHandling";
import { DeleteUserButtonField } from "@/features/user/components/DeleteUserButtonField";
import { UpdateEmailPhoneRow } from "@/features/user/components/UpdateEmailPhoneRow";
import { userRoles } from "@/features/user/consts/userRoles";
import { useUpdateUserEmailPasswordMutation } from "@/generated/graphql-hooks";

export const EditUserForm = () => {
  const { handleError } = useErrorHandling();
  const form = useForm({ resolver: yupResolver(confirmPasswordFieldSchema) });
  const { firebaseUser } = useFirebaseUserContext();
  const { user } = useUserContext();

  const { mutateAsync: updateUserEmailPassword } =
    useUpdateUserEmailPasswordMutation();

  const onUpdatePassword = async ({ password }) => {
    if (!firebaseUser?.email) return;
    try {
      await updateUserEmailPassword({ newPassword: password });
      toast.success("成功更新密碼");
    } catch (error: any) {
      handleError(error, {
        tags: {
          action: "ManageUserForm.onUpdatePassword",
        },
      });
    }
  };

  useEffect(() => {
    form.reset({
      ...(firebaseUser?.phoneNumber && {
        countryCode: firebaseUser.phoneNumber.substring(1, 4),
        phone: firebaseUser.phoneNumber.substring(
          4,
          firebaseUser.phoneNumber.length,
        ),
      }),
      ...(firebaseUser?.email && { email: firebaseUser.email }),
    });
  }, [firebaseUser?.email, firebaseUser?.phoneNumber]);

  return (
    <CardFormContainer>
      <FormProvider {...form}>
        <form
          className="space-y-5"
          onSubmit={(e) => {
            e.preventDefault();
            runIfNotTestUser(
              firebaseUser?.uid,
              form.handleSubmit(onUpdatePassword),
            );
          }}
        >
          <Title size="h2" className="text-gray-900 font-medium">
            編輯帳戶
          </Title>
          <UpdateEmailPhoneRow />

          <div className="grid gap-3 sm:grid-cols-2 sm:gap-4">
            <TextField
              label="新密碼"
              type="password"
              errorMessage={form.formState.errors?.password?.message}
              {...form.register("password")}
            />
            <TextField
              label="確認密碼"
              type="password"
              errorMessage={form.formState.errors?.confirmPassword?.message}
              {...form.register("confirmPassword")}
            />
          </div>

          <div className="grid gap-3 sm:grid-cols-2 sm:gap-4">
            <TextField
              label="帳戶身份"
              value={userRoles[user?.role]}
              disabled
            />
            <DeleteUserButtonField />
          </div>

          <div className="pt-4 sm:flex sm:justify-end">
            <Button
              className="sm:w-max"
              type="submit"
              isLoading={form.formState.isSubmitting}
            >
              確定
            </Button>
          </div>
        </form>
      </FormProvider>
    </CardFormContainer>
  );
};
