import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { PhoneField } from "@/features/auth/components/PhoneField";
import { useFirebaseUserContext } from "@/features/auth/contexts/useFirebaseUserContext";
import { runIfNotTestUser } from "@/features/auth/helpers/runIfNotTestUser";
import { Button } from "@/features/common/components/Button";
import { TextField } from "@/features/common/components/form/TextField";
import {
  DialogViewEnum,
  useDialogContext,
} from "@/features/common/contexts/useDialogContext";

export const UpdateEmailPhoneRow = () => {
  const { openDialog } = useDialogContext();
  const { firebaseUser } = useFirebaseUserContext();
  const { register } = useFormContext();

  const emailVerified = useMemo(() => {
    if (!firebaseUser?.email) return "";

    if (!firebaseUser?.emailVerified) return "(未驗證)";

    return "(已驗證)";
  }, [firebaseUser?.email, firebaseUser?.emailVerified]);

  return (
    <div className="grid gap-3 sm:grid-cols-2 sm:gap-4">
      <PhoneField
        disabled
        actionButton={
          <Button
            variant="text"
            className="!text-primary !bg-transparent !px-3"
            onClick={() =>
              runIfNotTestUser(firebaseUser?.uid, () =>
                openDialog({
                  actionType: !firebaseUser?.phoneNumber ? "add" : "update",
                  view: DialogViewEnum.UpdateUserPhoneDialog,
                }),
              )
            }
          >
            {!firebaseUser?.phoneNumber ? "未綁定" : "變更"}
          </Button>
        }
      />
      <TextField
        label={`電郵${emailVerified}`}
        {...register("email")}
        disabled
        actionButton={
          <Button
            variant="text"
            className="!text-primary !bg-transparent !px-3"
            onClick={() =>
              runIfNotTestUser(firebaseUser?.uid, () =>
                openDialog({
                  actionType: !firebaseUser?.email ? "add" : "update",
                  view: DialogViewEnum.UpdateUserEmailDialog,
                }),
              )
            }
          >
            {!firebaseUser?.email ? "未綁定" : "變更"}
          </Button>
        }
      />
    </div>
  );
};
