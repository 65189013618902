import { TiPlus } from "react-icons/ti";

import { MobileBottomNavItemType } from "@/features/layout/types/MobileBottomNavItemType";
import { UserRoleEnum } from "@/generated/graphql-hooks";
import HomeIcon from "@/icons/mobileBottomNavBar/home.svg";
import JobIcon from "@/icons/mobileBottomNavBar/job.svg";
import KolIcon from "@/icons/mobileBottomNavBar/kol.svg";

export const mobileBottomNavigationItems: MobileBottomNavItemType[] = [
  {
    name: "首頁",
    path: "/",
    icon: <HomeIcon />,
  },
  {
    name: "KOL",
    path: "/kol",
    icon: <KolIcon />,
  },
  {
    name: "刊登",
    path: "/business/createJob",
    role: UserRoleEnum.Business,
    icon: (
      <div className="rounded-lg p-1 bg-primary">
        <TiPlus size={20} className="text-white" />
      </div>
    ),
  },
  {
    name: "通告欄",
    path: "/job",
    icon: <JobIcon />,
  },
];
