import { FileWithPath } from "@mantine/dropzone";
import * as Sentry from "@sentry/nextjs";
import toast from "react-hot-toast";

import {
  AccessTypeEnum,
  FileSourceTypeEnum,
  FileTypeEnum,
  FileUploadCreateInput,
} from "@/generated/graphql-hooks";
import { getPublicConfig } from "@/helpers/getPublicConfig";

export const uploadImages = async (
  fileList: FileList | FileWithPath[],
  accessType: AccessTypeEnum = AccessTypeEnum.Public,
): Promise<FileUploadCreateInput[] | null> => {
  const {
    services: { cloudinary },
  } = getPublicConfig();

  const compressImage = await import("@/helpers/compressImage").then(
    (mod) => mod.compressImage,
  );

  try {
    const requests = Array.from(fileList).map(async (file) => {
      const compressedImage = await compressImage(file);

      const formData = new FormData();
      formData.append("file", compressedImage);
      formData.append("upload_preset", cloudinary.uploadPreset);

      return fetch(cloudinary.uploadEndpoint, {
        method: "POST",
        body: formData,
      });
    });

    const responses = await Promise.all(requests);
    const promises = responses.map((res) => res.json());
    const results = await Promise.all(promises);

    return results.map((result) => ({
      accessType,
      fileType: FileTypeEnum.Image,
      height: result.height,
      publicId: result.public_id,
      sourceType: FileSourceTypeEnum.Cloudinary,
      url: result.url,
      width: result.width,
    }));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTag("action", "cloudinary.uploadImages");
      Sentry.captureException(error);
    });
    toast.error("上傳圖像發生錯誤，請重新嘗試。");
    return null;
  }
};
