import { Popover as TPopover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";

import type { FCWithChildren } from "koljobs-types";

import { tailwindScreenSizes } from "@/features/common/consts/tailwindScreenSizes";

export const Popover: FCWithChildren<{
  button: ReactNode;
}> = ({ button, children }) => {
  return (
    <TPopover className="z-50 inline-flex items-center md:relative">
      {({ open }) => (
        <>
          <TPopover.Button
            className={clsx(
              open ? "text-primary" : "text-gray-700",
              "outline-0",
            )}
          >
            {button}
          </TPopover.Button>
          <Transition
            as="div"
            {...(window.innerWidth >= tailwindScreenSizes.md && {
              enter: "transition ease-out duration-200",
              enterFrom: "opacity-0 translate-y-1",
              enterTo: "opacity-100 translate-y-0",
              leave: "transition ease-in duration-150",
              leaveFrom: "opacity-100 translate-y-0",
              leaveTo: "opacity-0 translate-y-1",
            })}
          >
            {open && (
              <TPopover.Panel
                static
                className="absolute right-0 mt-5 w-screen px-4 sm:max-w-sm"
              >
                {children}
              </TPopover.Panel>
            )}
          </Transition>
        </>
      )}
    </TPopover>
  );
};
