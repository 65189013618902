import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Checkbox } from "@/features/common/components/form/Checkbox";
import { jobScales } from "@/features/job/const/jobScales";
import { SettingSectionCard } from "@/features/modelNotificationSettings/components/SettingSectionCard";

export const JobScaleSection = () => {
  const { register, watch, setValue } = useFormContext();
  const jobScaleValues = watch("jobScales.set");

  const isSelectedAll = useMemo(
    () => jobScaleValues?.length === Object.keys(jobScales).length,
    [jobScaleValues],
  );

  return (
    <SettingSectionCard title="接受工作規模 (件計工作)">
      <Checkbox
        className="w-max"
        labelClassName="font-light"
        variant="square"
        label="所有規模"
        onChange={() => {
          setValue(
            "jobScales.set",
            isSelectedAll ? [] : Object.keys(jobScales),
          );
        }}
        checked={isSelectedAll}
      />

      {Object.entries(jobScales).map(([value, label], index) => (
        <Checkbox
          className="w-max"
          labelClassName="font-light"
          key={index}
          variant="square"
          label={label}
          value={value}
          checked={jobScaleValues.includes(value)}
          {...register(`jobScales.set`)}
        />
      ))}
    </SettingSectionCard>
  );
};
