import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, VariantProps } from "class-variance-authority";
import React from "react";
import { twc } from "react-twc";

import * as ShadcnSheet from "@/features/common/components/shadcn/sheet";
import CloseIcon from "@/icons/close.svg";
import { cn } from "@/lib/utils";

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof ShadcnSheet.sheetVariants> {}
const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ side = "right", className, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content
      ref={ref}
      className={cn(ShadcnSheet.sheetVariants({ side }), className)}
      {...props}
    >
      {children}
      <SheetPrimitive.Close className="fixed left-4 top-4 transition-opacity opacity-70 hover:opacity-100 disabled:pointer-events-none data-[state=open]:bg-secondary">
        <CloseIcon className="text-gray-50" />
        <span className="sr-only">Close</span>
      </SheetPrimitive.Close>
    </SheetPrimitive.Content>
  </SheetPortal>
));

const Sheet = ShadcnSheet.Sheet;
const SheetPortal = ShadcnSheet.SheetPortal;
const SheetOverlay = twc(ShadcnSheet.SheetOverlay)`bg-black/60`;
const SheetTrigger = ShadcnSheet.SheetTrigger;
const SheetClose = ShadcnSheet.SheetClose;
const SheetHeader = ShadcnSheet.SheetHeader;
const SheetFooter = ShadcnSheet.SheetFooter;
const SheetTitle = ShadcnSheet.SheetTitle;
const SheetDescription = ShadcnSheet.SheetDescription;

export {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetContent,
  SheetClose,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
};
