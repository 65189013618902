import { VideoCameraIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import { formatDistanceToNow } from "date-fns";
import { zhHK } from "date-fns/locale";
import Link from "next/link";

import {
  DialogViewEnum,
  useDialogContext,
} from "@/features/common/contexts/useDialogContext";
import { notificationEvents } from "@/features/job/const/notificationEvents";
import { RateModelDialogButton } from "@/features/modelRating/components/RateModelDialogButton";
import {
  NotificationEventEnum,
  NotificationFragment,
  UserRoleEnum,
} from "@/generated/graphql-hooks";

export const NotificationItem = ({
  notification,
  unread = false,
}: {
  notification: NotificationFragment;
  unread?: boolean;
}) => {
  const { openDialog } = useDialogContext();

  const onOpenJobDetailsDialog = () => {
    openDialog({
      job: notification.job,
      view: DialogViewEnum.JobDetailsDialog,
    });
  };

  return (
    <div
      className={clsx(
        "border-l-4",
        unread ? "bg-secondary border-primary" : "border-transparent",
      )}
    >
      <div className="px-5 py-3">
        <div className="flex flex-col space-y-1">
          <div className="flex space-x-1 text-base">
            <div className="flex space-x-1">
              {!!notification.sender && (
                <Link
                  href={`/${
                    UserRoleEnum[notification.sender.__typename] ===
                    UserRoleEnum.Model
                      ? "kol"
                      : "business"
                  }/${notification.sender.id}/${notification.sender.username}`}
                  passHref
                  className="hover:text-gray-500"
                >
                  {notification.sender.username}
                </Link>
              )}
              <span className="font-light">
                {notificationEvents[notification.event]}
              </span>
              {notification.event ===
                NotificationEventEnum.SystemRemindBusinessInviteJob && (
                <RateModelDialogButton jobId={notification.job.id} />
              )}
              {notification.event ===
                NotificationEventEnum.BusinessSendVideoCallRequest && (
                <VideoCameraIcon className="inline h-5 w-5" />
              )}
            </div>
          </div>
          <div className="flex items-end justify-between">
            <div>
              <div className="flex space-x-1 truncate text-sm text-gray-500">
                <span className="font-light">通告:</span>
                <button
                  onClick={onOpenJobDetailsDialog}
                  className="truncate hover:text-gray-400"
                >
                  {notification.job?.title ?? "-"}
                </button>
              </div>
            </div>
            <div className="whitespace-nowrap text-xs text-gray-500">
              {formatDistanceToNow(new Date(notification.createdAt), {
                locale: zhHK,
              })}
              前
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
