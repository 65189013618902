import type { FCWithChildren } from "koljobs-types";

export const SettingSectionCard: FCWithChildren<{
  title: string;
  gridColumns?: number | string;
}> = ({ children, title, gridColumns = 2 }) => {
  return (
    <div className="space-y-4 rounded-lg bg-white p-4 shadow">
      <div className="text-lg text-gray-900">{title}</div>
      <div className={`grid grid-cols-${gridColumns} gap-6`}>{children}</div>
    </div>
  );
};
