import { Model, Business } from "@prisma/client";
import Image from "next/image";
import { useMemo, useState } from "react";

import { HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { useFirebaseUserContext } from "@/features/auth/contexts/useFirebaseUserContext";
import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Avatar } from "@/features/common/components/Avatar";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/features/common/components/Sheet";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";
import { MobileSideMenuHeader } from "@/features/layout/components/mobile/MobileSideMenuHeader";
import { MobileSideMenuItem } from "@/features/layout/components/mobile/MobileSideMenuItem";
import MenuIcon from "@/icons/mobileBottomNavBar/menu.svg";

import { mobileSideNavItems } from "../../consts/mobileSideNavItems";

export const MobileSideMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUserContext();
  const { trackEvent } = useAnalytics();
  const { signOut } = useFirebaseUserContext();

  const sideMenuItems = useMemo(
    () =>
      mobileSideNavItems.filter(
        (navItem) => !navItem?.role || navItem?.role === user?.role,
      ),
    [user?.role],
  );
  const avatarPublicId = useMemo(() => {
    if (user?.roleData?.__typename === "Business") {
      return user.roleData.logoPublicId;
    }
    if (user?.roleData?.__typename === "Model") {
      return user.roleData.avatarPublicId;
    }
    return null;
  }, [user?.roleData]);

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger className="text-gray-500 text-xs flex flex-col px-5.5 py-0.5 items-center gap-0.5 flex-shrink-0">
        <div className="relative">
          <Avatar
            size="mobileBottomNavBar"
            publicId={avatarPublicId}
            name={(user?.roleData as unknown as Model | Business)?.username}
          />
          <MenuIcon className="absolute z-50 top-1/2 left-1/2" />
        </div>
        我
      </SheetTrigger>
      <SheetContent className="p-0">
        <SheetHeader className="flex h-14 py-2 border-b-2 border-b-sky-50">
          <SheetTitle className="flex px-4 h-8 w-auto items-center">
            <Image
              alt="logo"
              src="/images/logo_mobile.png"
              layout="fixed"
              width={22}
              height={28}
              priority
              unoptimized
            />
          </SheetTitle>
        </SheetHeader>
        <MobileSideMenuHeader onClick={() => setIsOpen(false)} />
        <div className="flex flex-col">
          {sideMenuItems?.map((sideMenuItem, index) => (
            <MobileSideMenuItem
              item={sideMenuItem}
              onClick={() => setIsOpen(false)}
            />
          ))}
          <button
            className="px-4 py-2 text-gray-900 hover:bg-gray-100 text-left cursor-pointer"
            onClick={() => {
              trackEvent(HeaderEventEnum.CLICK_SIGN_OUT);
              signOut();
              () => setIsOpen(false);
            }}
          >
            登出
          </button>
        </div>
      </SheetContent>
    </Sheet>
  );
};
