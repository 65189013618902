import { Disclosure } from "@headlessui/react";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";

import { HeaderEventEnum } from "@/consts/AnalyticsEvent";
import { SignInButton } from "@/features/auth/components/signIn/SignInButton";
import { SignUpButton } from "@/features/auth/components/signUp/SignUpButton";
import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Button } from "@/features/common/components/Button";
import { Container } from "@/features/common/components/Container";
import { useAnalytics } from "@/features/common/hooks/useAnalytics";
import { Logo } from "@/features/layout/components/Logo";
import { NavItem } from "@/features/layout/components/NavItem";
import { PublicNavMobileMenuButton } from "@/features/layout/components/mobile/PublicNavMobileMenuButton";
import { PublicNavMobilePanel } from "@/features/layout/components/mobile/PublicNavMobilePanel";
import { mainNavigationItems } from "@/features/layout/consts/mainNavigationItems";
import { NotificationButton } from "@/features/notification/components/NotificationButton";
import { UserDropdown } from "@/features/user/components/UserDropdown";
import { UserRoleEnum } from "@/generated/graphql-hooks";
import { cn } from "@/lib/utils";

type NavbarProps = {
  mobileLayout: boolean;
};

export const Navbar = ({ mobileLayout }: NavbarProps) => {
  const [open, setOpen] = useState(false);
  const { pathname, push } = useRouter();
  const { user } = useUserContext();
  const { trackEvent } = useAnalytics();
  const navItems = useMemo(
    () =>
      mainNavigationItems.filter(
        (navItem) => !navItem?.role || navItem?.role === user?.role,
      ),
    [user?.role],
  );

  return (
    <Disclosure
      as="nav"
      className={cn(
        "sticky top-0 z-50 bg-white shadow",
        mobileLayout ? "hidden sm:block" : "",
      )}
    >
      {({ open }) => {
        setOpen(open);
        return (
          <div
            className={
              open
                ? "shadow-lg w-full overflow-y-auto bg-white sm:z-50 sm:absolute"
                : undefined
            }
          >
            <Container className="flex h-14 w-full justify-between sm:h-20">
              <div className="flex flex-shrink-0 items-center">
                <Logo />
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
                {navItems?.map(
                  (navItem, index) =>
                    "path" in navItem && (
                      <NavItem
                        active={pathname === navItem.path}
                        label={navItem.name}
                        path={navItem.path}
                        key={index}
                      />
                    ),
                )}
                {!!user && (
                  <div className="inline-flex items-center pr-3">
                    <NotificationButton />
                  </div>
                )}
                {user?.role === UserRoleEnum.Business && (
                  <div className="flex items-center justify-center">
                    <Button
                      className="h-max whitespace-nowrap !px-2 !py-1 font-light"
                      onClick={() => {
                        trackEvent(HeaderEventEnum.CLICK_CREATE_JOB);
                        push("/business/createJob");
                      }}
                    >
                      刊登通告
                    </Button>
                  </div>
                )}
                <div className="my-7 inline-flex w-[1px] items-center bg-gray-200"></div>
                <div className="flex items-center justify-center">
                  {!user && (
                    <div className="flex space-x-4">
                      <SignUpButton onNavbar />
                      <SignInButton onNavbar />
                    </div>
                  )}

                  {!!user && <UserDropdown />}
                </div>
              </div>

              {/* Mobile menu button */}
              <div className="-mr-2 flex items-center space-x-2 sm:hidden">
                {!!user && <NotificationButton />}
                {!user && !open && <SignInButton onNavbar />}
                {!user && <PublicNavMobileMenuButton open={open} />}
              </div>
            </Container>
            <PublicNavMobilePanel />
          </div>
        );
      }}
    </Disclosure>
  );
};
