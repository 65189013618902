import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Button } from "@/features/common/components/Button";
import { Divider } from "@/features/common/components/Divider";
import { CardFormContainer } from "@/features/common/components/form/CardFormContainer";
import { useErrorHandling } from "@/features/common/hooks/useErrorHandling";
import { useInvalidateQueries } from "@/features/common/hooks/useInvalidateQueries";
import { ModelBasicInfoSection } from "@/features/model/components/editProfile/EditProfileForm/ModelBasicInfoSection";
import { ModelPrivateInfoSection } from "@/features/model/components/editProfile/EditProfileForm/ModelPrivateInfoSection";
import { EditProfileInputs } from "@/features/model/types/EditProfileInputs";
import { editProfileFormYupResolver } from "@/features/model/yup/editProfileFormYupResolver";
import {
  ModelWithProfileStatusFragment,
  useUpdateModelMutation,
} from "@/generated/graphql-hooks";
import { uploadImages } from "@/services/cloudinary/uploadImages";

export const EditProfileForm = () => {
  const { user } = useUserContext();
  const { handleGraphqlError } = useErrorHandling();

  const form = useForm<EditProfileInputs>({
    mode: "onChange",
    resolver: editProfileFormYupResolver,
  });
  const { mutateAsync: updateModel } = useUpdateModelMutation();
  const { invalidateQueries } = useInvalidateQueries();

  const onUpdateModel = async (data) => {
    if (!user) return;

    const {
      avatar,
      defaultAvatar,
      email,
      countryCode,
      phone,
      extraInfo,
      ...inputs
    } = data;
    const uploadedAvatar = !!avatar?.[0] && (await uploadImages(avatar));

    try {
      await updateModel({
        data: {
          ...Object.fromEntries(
            Object.entries<Record<"set", string | number>>(inputs).map(
              ([key, value]) => [key, { set: value.set || null }],
            ),
          ),
        },
        ...(!!uploadedAvatar?.[0] && { avatar: uploadedAvatar[0] }),
        email,
        phone: !!phone ? `+${countryCode}${phone}` : null,
        extraInfo,
      });

      invalidateQueries(["Model", "User"]);

      toast.success("成功更新個人資料");
    } catch (error) {
      handleGraphqlError(error, {
        tags: { action: "EditProfileForm.onUpdateModel" },
      });
    }
  };

  useEffect(() => {
    if (!user?.roleData) return;

    const model = user.roleData as ModelWithProfileStatusFragment;
    const modelPrivatePhone = model.privateInfo?.phone;

    form.reset({
      defaultAvatar: model.avatarPublicId,
      username: { set: model.username },
      prefJobType: { set: model.prefJobType },
      description: { set: model.description },
      experience: { set: model.experience },
      height: { set: model.height },
      weight: { set: model.weight },
      chest: { set: model.chest },
      cupSize: { set: model.cupSize },
      waist: { set: model.waist },
      hips: { set: model.hips },
      shoeSize: { set: model.shoeSize },
      instagram: { set: model.instagram },
      littleRedBook: { set: model.littleRedBook },
      youtube: { set: model.youtube },
      email: model.privateInfo?.email,
      ...(!!modelPrivatePhone && {
        countryCode: parseInt(modelPrivatePhone.substring(1, 4)),
        phone: modelPrivatePhone.substring(4, modelPrivatePhone.length),
      }),
      extraInfo: model.privateInfo?.extraInfo,
    });
  }, [user?.roleData]);

  return (
    <CardFormContainer>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onUpdateModel)} className="space-y-5">
          <ModelBasicInfoSection />

          <div className="py-3">
            <Divider />
          </div>

          <ModelPrivateInfoSection />

          <div className="pt-4 sm:flex sm:justify-end">
            <Button
              className="sm:w-max"
              type="submit"
              isLoading={form.formState.isSubmitting}
            >
              確定
            </Button>
          </div>
        </form>
      </FormProvider>
    </CardFormContainer>
  );
};
