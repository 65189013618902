import clsx from "clsx";

import type { FCWithChildren } from "koljobs-types";

export const Divider: FCWithChildren<{
  className?: string;
  text?: string;
}> = ({ className, text }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className={clsx("w-full border-t border-gray-200", className)} />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-base font-light text-gray-700">
          {text}
        </span>
      </div>
    </div>
  );
};
