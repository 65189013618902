import { Controller, useFormContext } from "react-hook-form";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { Title } from "@/features/common/components/Title";
import { MultiSelect } from "@/features/common/components/form/MultiSelect";
import { SingleImageUploadField } from "@/features/common/components/form/SingleImageUploadField";
import { TextField } from "@/features/common/components/form/TextField";
import { ModelBodyFields } from "@/features/model/components/editProfile/EditProfileForm/ModelBodyFields";
import { modelPrefJobTypes } from "@/features/model/const/modelPrefJobTypes";
import { EditProfileInputs } from "@/features/model/types/EditProfileInputs";
import { ModelFragment } from "@/generated/graphql-hooks";

export const ModelBasicInfoSection = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<EditProfileInputs>();
  const { user } = useUserContext();

  return (
    <div className="space-y-5">
      <Title size="h2" className="text-gray-900 font-medium">
        個人資料
      </Title>

      <div className="pb-3">
        <Controller
          control={control}
          name="avatar"
          render={({
            field: { name, value, onChange },
            formState: { errors },
          }) => (
            <SingleImageUploadField
              required
              label="頭像"
              value={value}
              name={name}
              onChange={onChange}
              publicId={(user?.roleData as ModelFragment)?.avatarPublicId}
              errorMessage={errors?.avatar?.message}
            />
          )}
        />
      </div>

      <TextField
        required
        label="顯示名稱"
        errorMessage={errors?.username?.set?.message}
        {...register("username.set")}
      />

      <TextField
        required
        label="簡介"
        multiLine
        rows={4}
        errorMessage={errors?.description?.set?.message}
        {...register("description.set")}
      />

      <TextField
        label="工作經驗 (選填)"
        multiLine
        rows={4}
        errorMessage={errors?.experience?.set?.message}
        {...register("experience.set")}
      />

      <Controller
        control={control}
        name="prefJobType.set"
        render={({ field: { ref, ...props }, formState: { errors } }) => (
          <MultiSelect
            required
            label="擅長工作"
            placeholder="請選擇擅長工作"
            options={Object.entries(modelPrefJobTypes).map(
              ([value, label]) => ({ value, label }),
            )}
            errorMessage={errors?.prefJobType?.set?.message}
            {...props}
          />
        )}
      />

      <div className="grid gap-3 sm:grid-cols-2 sm:gap-4">
        <TextField
          label="Instagram"
          placeholder="輸入你的IG名稱"
          errorMessage={errors?.instagram?.set?.message}
          prefix="@"
          {...register("instagram.set")}
        />
        <TextField
          label="小紅書號"
          placeholder="輸入你的小紅書號"
          errorMessage={errors?.littleRedBook?.set?.message}
          {...register("littleRedBook.set")}
        />
      </div>

      <TextField
        label="YouTube"
        placeholder="輸入你的帳號代碼"
        errorMessage={errors?.youtube?.set?.message}
        prefix="youtube.com/@"
        {...register("youtube.set")}
      />

      <ModelBodyFields />
    </div>
  );
};
