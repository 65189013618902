import clsx from "clsx";

import { CloudinaryImage } from "@/features/common/components/CloudinaryImage";

type AvatarProps = {
  size?: "sm" | "md" | "lg" | "mobileBottomNavBar";
} & Partial<Record<"className" | "publicId" | "name", string>>;

const sizeClasses = {
  sm: "h-8 w-8",
  md: "h-8 w-8 sm:h-12 sm:w-12",
  lg: "h-16 w-16 sm:h-20 sm:w-20",
  mobileBottomNavBar: "h-[28px] w-[28px]",
};

export const Avatar = ({
  className,
  publicId,
  name,
  size = "sm",
}: AvatarProps) => {
  return (
    <div
      className={clsx(
        sizeClasses[size],
        "relative flex items-center justify-center rounded-full bg-gray-400 text-white",
        className,
      )}
    >
      <CloudinaryImage
        className="rounded-full"
        publicId={publicId}
        alt={name}
      />
      {!!name && <span>{name.charAt(0)}</span>}
    </div>
  );
};
