import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

import { PublicMobileNavItemType } from "@/features/layout/types/PublicMobileNavItemType";

const buttonClassNames =
  "w-full px-14 block py-3 text-base font-normal hover:text-primary hover:bg-gray-50 text-left text-gray-900";

export const PublicNavMobileItem = (props: PublicMobileNavItemType) => {
  const router = useRouter();

  return (
    <Disclosure.Button
      href={props.path}
      passHref
      as={Link}
      className={clsx(
        buttonClassNames,
        props.path === router.asPath && "!text-primary",
      )}
    >
      {props.name}
    </Disclosure.Button>
  );
};
