import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { Button } from "@/features/common/components/Button";
import { useErrorHandling } from "@/features/common/hooks/useErrorHandling";
import { useInvalidateQueries } from "@/features/common/hooks/useInvalidateQueries";
import { useLeavePageConfirm } from "@/features/common/hooks/useLeavePageConfirm";
import { EnableNotificationSection } from "@/features/modelNotificationSettings/components/EnableNotificationSection";
import { JobScaleSection } from "@/features/modelNotificationSettings/components/JobScaleSection";
import { JobTimeSection } from "@/features/modelNotificationSettings/components/JobTimeSection";
import { SelectJobTypeSection } from "@/features/modelNotificationSettings/components/SelectJobTypeSection";
import {
  ModelNotificationSettingsCreateInput,
  useModelNotificationSettingsQuery,
  useUpsertModelNotificationSettingsMutation,
} from "@/generated/graphql-hooks";

const defaultNotificationSettings = {
  enableNotification: false,
  jobTypes: { set: [] },
  jobTimes: { set: [] },
  jobScales: { set: [] },
};

export const NotificationSettingsForm = () => {
  const form = useForm<ModelNotificationSettingsCreateInput>({
    mode: "onChange",
    defaultValues: defaultNotificationSettings,
  });
  const { handleGraphqlError } = useErrorHandling();
  const { data: { modelNotificationSettings } = {} } =
    useModelNotificationSettingsQuery();
  const { mutateAsync: upsertModelNotificationSettings } =
    useUpsertModelNotificationSettingsMutation();
  const { invalidateQueries } = useInvalidateQueries();

  const onUpsert = async (data: ModelNotificationSettingsCreateInput) => {
    try {
      await upsertModelNotificationSettings({
        create: data,
        update: {
          ...data,
          enableNotification: {
            set: data.enableNotification,
          },
        },
      });
      invalidateQueries(["ModelNotificationSettings"]);
      toast.success("成功更新通知設定");
      window.scrollTo(0, 0);
    } catch (error) {
      handleGraphqlError(error, {
        tags: {
          action: "useNotificationSettingsContext.onUpsert",
        },
      });
    }
  };

  useLeavePageConfirm(
    form.formState.isDirty && !form.formState.isSubmitted,
    "尚未儲存你的通知設定，確定要離開嗎？",
  );

  useEffect(() => {
    if (!modelNotificationSettings) return;

    const { enableNotification, jobTypes, jobTimes, jobScales } =
      modelNotificationSettings;

    form.reset({
      enableNotification,
      jobTypes: { set: jobTypes },
      jobTimes: { set: jobTimes },
      jobScales: { set: jobScales },
    });
  }, [modelNotificationSettings]);

  return (
    <FormProvider {...form}>
      <form
        className="space-y-3 p-4 sm:p-0"
        onSubmit={form.handleSubmit(onUpsert)}
      >
        <div className="text-base font-light text-gray-900">
          設定好條件並開啟通知，當有符合條件的通告出現，便會馬上通知你
        </div>
        <EnableNotificationSection />

        <SelectJobTypeSection />

        {/* @todo */}
        {/* <SettingSectionCard title="接受商戶層級"></SettingSectionCard> */}

        <JobTimeSection />

        <JobScaleSection />

        <div className="pt-4 sm:float-right sm:w-20 sm:py-4">
          <Button
            className="py-2 px-5"
            type="submit"
            disabled={form.formState.isSubmitting}
            isLoading={form.formState.isSubmitting}
          >
            確定
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
