import { ExclamationIcon } from "@heroicons/react/solid";
import * as Sentry from "@sentry/nextjs";
import { useEffect, useMemo, useState } from "react";
import { QueryObserverBaseResult } from "react-query";

import { useGraphqlError } from "koljobs-helpers/src/hooks/useGraphqlError";

import { IconButton } from "@/features/common/components/IconButton";

const ErrorMessage = (
  { error, sentryEventId }: { error: unknown; sentryEventId: string | null },
) => {
  const { serverSentryEventId, errorMessage } = useGraphqlError({
    error,
  });

  const eventId = useMemo(
    () => serverSentryEventId ?? sentryEventId,
    [sentryEventId, serverSentryEventId],
  );

  return (
    <span className="text-center">
      {errorMessage} ({eventId && eventId.substring(0, 7)})
    </span>
  );
};

export const ErrorRetry = (
  { refetch, error }: Pick<QueryObserverBaseResult, "refetch" | "error">,
) => {
  const [sentryEventId, setSentryEventId] = useState<string | null>(null);

  useEffect(() => {
    setSentryEventId(Sentry.lastEventId());
  }, []);

  if (!error) return null;

  return (
    <div className="flex flex-1 flex-col items-center justify-center space-y-2 py-28">
      <ExclamationIcon className="text-primary h-32 w-32" />
      <div className="flex flex-col items-center space-y-2">
        <div className="text-lg font-normal text-gray-700">發生錯誤</div>
        <ErrorMessage error={error} sentryEventId={sentryEventId} />
        <IconButton
          className="text-base font-light text-gray-500"
          onClick={() => refetch()}
        >
          重試
        </IconButton>
      </div>
    </div>
  );
};
