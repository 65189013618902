import { BusinessEventEnum, ModelEventEnum } from "@/consts/AnalyticsEvent";
import { businessEditProfileTabs } from "@/features/business/consts/businessEditProfileTabs";
import { modelEditProfileTabs } from "@/features/model/const/modelEditProfileTabs";
import { UserRoleEnum } from "@/generated/graphql-hooks";

export const dropdownNavigationItems = [
  {
    name: "個人資料",
    event: ModelEventEnum.EDIT_PROFILE,
    role: UserRoleEnum.Model,
    paths: modelEditProfileTabs.flatMap(({ component, ...rest }) => ({
      ...rest,
    })),
  },
  {
    name: "個人資料",
    event: BusinessEventEnum.CLICK_EDIT_PROFILE_PAGE,
    role: UserRoleEnum.Business,
    paths: businessEditProfileTabs.flatMap(({ component, ...rest }) => ({
      ...rest,
    })),
  },
];
