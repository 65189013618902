import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, MouseEvent, ReactNode } from "react";

type DropdownProps = {
  alignment?: "left" | "right";
  button: ({ open }: { open: boolean }) => JSX.Element;
  header?: ReactNode;
  options: {
    className?: string;
    name: ReactNode;
    selected?: boolean;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  }[];
};

export const Dropdown = (
  { alignment, button: Button, header, options }: DropdownProps,
) => {
  return (
    <Menu as="div" className="relative z-40 text-left">
      {({ open }) => (
        <>
          <div className="flex items-center">
            <Menu.Button as="div" className="inline-flex w-full">
              <Button open={open} />
            </Menu.Button>
          </div>
          {!!options.length && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={clsx(
                  alignment === "left" ? "" : "right-0",
                  "absolute mt-2 w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                )}
              >
                {header}
                <div className="py-1">
                  {options.map(
                    ({ className, name, selected, onClick }, index) => (
                      <Menu.Item key={index}>
                        {({ active }) => (
                          <button
                            className={clsx(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-base font-light",
                              !!selected && "!text-primary hover:!text-primary",
                              className,
                            )}
                            onClick={onClick}
                          >
                            {name}
                          </button>
                        )}
                      </Menu.Item>
                    ),
                  )}
                </div>
              </Menu.Items>
            </Transition>
          )}
        </>
      )}
    </Menu>
  );
};
