import { ExclamationIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";

export const ModelProfileCompletionAlert = () => {
  const { push } = useRouter();

  return (
    <div className="fixed left-1/2 top-5 z-50 w-10/12 -translate-x-1/2 border-l-4 border-yellow-400 bg-yellow-50 p-4 sm:w-max">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            閣下未填寫申請工作所需的個人資料，
            <button
              className="font-bold"
              onClick={() => push("/kol/editProfile")}
            >
              請按此前往填寫
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};
