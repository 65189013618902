import { useFormContext } from "react-hook-form";

import { PhoneField } from "@/features/auth/components/PhoneField";
import { Title } from "@/features/common/components/Title";
import { TextField } from "@/features/common/components/form/TextField";

export const ModelPrivateInfoSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="space-y-5">
      <div className="space-y-2">
        <Title size="h2" className="text-gray-900 font-medium">
          隱藏資料
        </Title>
        <div className="text-base font-light text-gray-900">
          以下隱藏資料只供成功配對的商戶查看，當通告完結後商戶便不能查看。
        </div>
      </div>

      <div className="grid gap-3 sm:grid-cols-2 sm:gap-4">
        <PhoneField label="工作電話" />

        <TextField
          label="工作電郵"
          type="email"
          errorMessage={errors?.email?.message}
          {...register("email")}
        />
      </div>

      <TextField
        label="額外資訊"
        multiLine
        rows={4}
        errorMessage={errors?.extraInfo?.message}
        {...register("extraInfo")}
      />
    </div>
  );
};
